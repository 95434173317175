<template>
  <div>
    <div class="floor">
      <div class="con">
        <div class="top-line"></div>
        <div class="msg">
          <div class="words">
            <p class="b-w">杭州蓝目数字信息技术有限公司</p>
            <!-- <p class="l-w">联系电话：0571-82310420</p> -->
            <p class="l-w">
              公司地址：浙江省杭州市萧山区经济技术开发区启迪路198号B 3A02-30号
            </p>
            <p class="l-w">
              <!-- 蓝目回访电话：15658183899 -->
              客服电话：19106842819
              <!-- <span style="color:#000000"> -------</span> -->
              <!-- 蓝目投诉电话：13634144964 -->
            </p>
            <p class="l-w">长沙运营中心：17711635555</p>
            <!-- <p class="l-w">Copyright © 2021 浙ICP备2020033336号</p> -->
          </div>
          <div class="CodeImg">
            <div style="width:15px;font-size:11px;color:#FFFFFF">
              扫码关注企业微信
            </div>
            <div class="code-img">
              <img :src="require('@/assets/images/code2024.png')" alt="" />
            </div>
            <!-- <div class="code-img">
              <img
                :src="require('@/assets/images/kefu202312.jpg')"
                alt=""
                style="width: 125px;height: 135px;"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="floor_bottom">
      <div class="f-b">
        <div class="njzq_logo">
          <!-- <span>合作券商：</span> -->
          <!-- <img :src="require('@/assets/images/njzq.png')" alt="" /> -->
        </div>
        <p class="l-b">
          Copyright © 2024
          <a
            href="https://beian.miit.gov.cn/"
            style="color: #FFFFFF;margin-left: 5px;padding-bottom:2px ;"
            >浙ICP备2020033336号</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.floor {
  width: 100%;
  min-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 333px;
  background: #000000;

  .con {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;

    .top-line {
      width: 100%;
      border-bottom: 1px solid #666666;
    }
  }

  .msg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .CodeImg {
      display: flex;
    }

    .code-img {
      padding-left: 15px;
    }
  }

  .words {
    text-align: left;
    color: #ffffff;
    line-height: 30px;

    .b-w {
      font-size: 22px;
      padding-bottom: 25px;
    }

    .l-w {
      font-size: 14px;
    }
  }
}

.f-b {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.floor_bottom {
  height: 95px;
  background: #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.njzq_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-b {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
